<template>
  <div class='sidebar MainLayoutSide'>
    <a-layout-sider
        class='admin-sider'
        v-model='collapsed'
        :trigger='null'
        :collapsible='true'
        collapsedWidth='70'
        width="240px"
        style='height: 100%'
    >
      <div class='logo-wrap'>
        <img src='@/assets/images/logo-collapsed.png' alt='' :style='styleLogo' height='auto' v-if="collapsed">
        <img src='@/assets/images/logo.png' alt='' :style='styleLogo' height='auto' v-else>
      </div>
      <div class="sidebar-menu">
        <a-menu
            mode='inline'
            :selected-keys='[currentKeyNav]'
            :default-open-keys="['3', '4', '5', '6']"
            style='border: none'
        >
          <template v-for='(item, index) in listNavigation'>
            <a-menu-item
                v-if="((item && !item.children) || (item && !item.children.length)) && item.visible"
                :key='item.key'
                @click='onNavigation(item.router)'
            >
              <a-icon :type='item.icon' />
              <span>{{ item.label }}</span>
            </a-menu-item>
            <a-sub-menu v-if="item && item.visible && item.children && item.children.length" :key="item.key">
              <span slot="title"><a-icon :type="item.icon" /><span>{{item.label}}</span></span>
              <a-menu-item v-for='(subItem, subIndex) in item.children' :key="subItem.key"
                           @click='onNavigation(subItem.router)'>
                {{subItem.label}}
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
      </div>
    </a-layout-sider>
  </div>

</template>

<script>
import WalletService from "@/services/app/wallet.service";
import {sortBy} from 'lodash'
export default {
  name: 'sider',
  props: ['collapsed'],
  data() {
    return {
      currentKeyNav: '1',
      listWallets: []
    }
  },
  created() {
    let currentPath = this.$route.path
    const currentRouteItem = this.flattenRouters.find(
        item => item.router === currentPath
    )
    if (currentRouteItem){
      this.currentKeyNav = currentRouteItem.key
    }
    this.onFetchListWallets()
  },
  methods: {
    onNavigation(router) {
      let currentPath = this.$router.currentRoute.path
      if (currentPath !== router) {
        this.$router.push({ path: router })
      }
    },
    async onFetchListWallets() {
      const response = await WalletService.getList()
      if (response.code === 1) {
        this.listWallets = response.data
      }
    }
  },
  watch: {
    '$route': {
      handler(value) {
        let currentPath = value.path
        const currentRouteItem = this.flattenRouters.find(
            item => item.router === currentPath
        )
        if (currentRouteItem){
          this.currentKeyNav = currentRouteItem.key
        }
      },
    },
    listWallets: {
      handler(value) {
        if (value.length) {
          let currentPath = this.$route.path
          const currentRouteItem = this.flattenRouters.find(
              item => item.router === currentPath
          )
          if (currentRouteItem){
            this.currentKeyNav = currentRouteItem.key
          }
        }
      },
    }
  },
  computed: {
    styleLogo() {
      return {
        width: this.collapsed ? '30px' : '100px'
      }
    },
    userRole() {
      let existUser = localStorage.getItem('User-Info')
      if (existUser) {
        return JSON.parse(existUser).roles || []
      }
    },
    listNavigation() {
      let output = [
        {
          label: 'Summary statistics',
          icon: 'shop',
          router: '/dashboard',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '1'
        },
        {
          label: 'Users management',
          icon: 'user',
          router: '/users',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '2'
        },
        {
          label: 'Game management',
          icon: 'rocket',
          router: '',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '3',
          children: [
            {
              label: 'Create pool',
              icon: '',
              router: '/create-pool/2',
              key: '3-1',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Edit pool',
              icon: '',
              router: '/edit-pool',
              key: '3-2',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Game action',
              icon: '',
              router: '/game-action',
              key: '3-3',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            }
          ]
        },
        {
          label: 'Support and Feedback',
          icon: 'message',
          router: '',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '5',
          children: [
            {
              label: 'User inquiries',
              icon: '',
              router: '/user-inquiries',
              key: '5-1',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Email supporter',
              icon: '',
              router: '/email-supporter',
              key: '5-2',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Feedback',
              icon: '',
              router: '/feedback',
              key: '5-3',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            }
          ]
        },
        {
          label: 'Jackpot',
          icon: 'crown',
          router: '',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '6',
          children: [
            {
              label: 'Config',
              icon: '',
              router: '/jackpot-config',
              key: '6-1',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Statistic',
              icon: '',
              router: '/jackpot-statistic',
              key: '6-2',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Management',
              icon: '',
              router: '/jackpot',
              key: '6-3',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            },
            {
              label: 'Subscribe',
              icon: '',
              router: '/jackpot-subscribe',
              key: '6-4',
              visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
            }
          ]
        },
        // {
        //   label: 'System configs',
        //   icon: 'setting',
        //   router: '/system-config',
        //   key: '44',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'Users',
        //   icon: 'user',
        //   key: '55',
        //   router: '/account',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'Report',
        //   icon: 'bar-chart',
        //   router: '/report',
        //   key: '66',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'PlantRefReport',
        //   icon: 'bar-chart',
        //   router: '/plant-report',
        //   key: '77',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'UserRefReport',
        //   icon: 'bar-chart',
        //   router: '/user-report',
        //   key: '88',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'Token Ingame Report',
        //   icon: 'bar-chart',
        //   router: '/token-report',
        //   key: '99',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'Update Price NFT',
        //   icon: 'setting',
        //   router: '/update-price',
        //   key: '100',
        //   visible: this.userRole.some(role => [].includes(role))
        // },
        // {
        //   label: 'Update Reward',
        //   icon: 'setting',
        //   router: '/update-reward',
        //   key: '101',
        //   visible: this.userRole.some(role => [].includes(role))
        // }
      ]
      if (this.listWallets.length) {
        output.push({
          label: 'Transactions',
          icon: 'wallet',
          router: '',
          visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          key: '4',
          children: sortBy(this.listWallets, 'id').map(item => ({
            label: item.name,
            icon: '',
            router: `/wallets/${item.id}`,
            key: `4-${item.id}`,
            visible: this.userRole.some(role => ['SUPER_ADMIN'].includes(role)),
          }))
        },)
      }
      return sortBy(output, 'key')
    },
    flattenRouters() {
      const flatten = (array) => {
        return array.reduce((acc, item) => {
          if (item.children) {
            acc.push(item);
            acc.push(...flatten(item.children));
          } else {
            acc.push(item);
          }
          return acc;
        }, []);
      };

      return flatten(this.listNavigation)
    },
  }
}
</script>

<style lang='scss' scoped>
.admin-sider {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

  .logo-wrap {
    margin-bottom: 15px;
    padding: 16px 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-inline-collapsed {
    width: 50px;

    li {
      padding: 0 17px !important;
    }
  }
}
.sidebar-menu {
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
</style>

<style lang="scss">
.MainLayoutSide {
  .ant-menu-submenu-vertical {
    .ant-menu-submenu-title {
      padding: 0 !important;
    }
  }
}
</style>
